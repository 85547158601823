import App from 'next/app';
import React from 'react';
import { ConfigProvider } from 'antd';
import NiceModal from '@ebay/nice-modal-react';
import { AuthContextProvider } from '$UTILS/hooks/useAuth';
import '$DATA/Less/merge.less';
import Head from 'next/head';

export default class MyApp extends App {
	render() {
		const { Component, pageProps } = this.props;
		let pathname = global.location && global.location.pathname.split('/')[1] || 'm3-001';
		return (
			<>
				<Head>
					<link rel="shortcut icon" href="/static/images/favicon.ico" />
				 	<title> { pathname && pathname === 'restrict' ? 'betway restriction page'  : 'Betway nhà cái Thể Thao hàng đầu Châu Âu, tỷ lệ hoàn trả cao nhất thị trường, tải xuống phiên bản di động APP ngay.' } </title>
				</Head>
				<ConfigProvider>
					<NiceModal.Provider>
						<AuthContextProvider>
								<Component {...pageProps} />
						</AuthContextProvider>
					</NiceModal.Provider>
				</ConfigProvider>

			</>

		)
	}
}
